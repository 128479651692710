import React, { useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import Auctions_dropdown from "../dropdown/Auctions_dropdown";
import { useDispatch, useSelector } from "react-redux";
import { bidsModalShow } from "../../redux/counterSlice";

const CategoryItem = () => {
  const [truckloads, setTruckloads] = useState([]);
  const { sortedtrendingCategoryItemData } = useSelector(
    (state) => state.counter
  );

  useEffect(() => {
    if (sortedtrendingCategoryItemData)
      setTruckloads(sortedtrendingCategoryItemData);
  }, []);

  const dispatch = useDispatch();
  const sortedTruckloads = [...truckloads]?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  return (
    <div className="grid grid-cols-1 gap-[1.875rem] md:grid-cols-2 lg:grid-cols-4">
      {sortedTruckloads
        ?.map((item) => {
          const { _id, name, price, category, isSold, images, store, slug } =
            item;
          return (
            <>
              <article key={_id}>
                <div className="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 rounded-2.5xl block border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg">
                  <figure className="relative">
                    <Link href={`/truckload/${slug || _id}`}>
                      <a>
                        <img
                          src={`${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/${images[0]?.url}`}
                          alt="item 5"
                          className="w-full h-[285px] rounded-[0.625rem] object-cover"
                        />
                      </a>
                    </Link>
                    {isSold && (
                      <div className="absolute left-3 -bottom-1">
                        <img src="/images/sold.svg" alt="sold" />
                      </div>
                    )}
                    <div
                      className={
                        store?.toLowerCase() === "hd"
                          ? "absolute top-3 left-3 font-display flex items-center space-x-1 rounded-md bg-orange-600 p-2"
                          : store?.toLowerCase() === "tgt"
                          ? "absolute top-3 left-3 font-display flex items-center space-x-1 rounded-md bg-red p-2"
                          : store?.toLowerCase() === "lws"
                          ? "absolute top-3 left-3 font-display flex items-center space-x-1 rounded-md bg-blue p-2"
                          : store?.toLowerCase() === "amz"
                          ? "absolute top-3 left-3 font-display flex items-center space-x-1 rounded-md bg-yellow p-2"
                          : store?.toLowerCase() === "dg"
                          ? "absolute top-3 left-3 font-display flex items-center space-x-1 rounded-md bg-yellow p-2"
                          : store?.toLowerCase() === "kl"
                          ? "absolute top-3 left-3 font-display flex items-center space-x-1 rounded-md bg-red p-2"
                          : store?.toLowerCase() === "wm"
                          ? "absolute top-3 left-3 font-display flex items-center space-x-1 rounded-md bg-blue p-2"
                          : "absolute top-3 left-3 font-display flex items-center space-x-1 rounded-md bg-red p-2"
                      }
                    >
                      <span
                        className={
                          store?.toLowerCase() === "wm"
                            ? "text-yellow text-sm"
                            : store?.toLowerCase() === "amz" ||
                              store?.toLowerCase() === "dg"
                            ? "text-black text-sm"
                            : "text-white text-sm"
                        }
                      >
                        {store}
                      </span>
                    </div>
                    <div className="absolute left-3 -bottom-3">
                      <div className="flex -space-x-2">
                        <Link href={`/truckload/${slug || _id}`}>
                          <a>
                            <Tippy content={<span>creator: {name}</span>}>
                              {/* <img
														src={creator.image}
														alt="creator"
														className="dark:border-jacarta-600 hover:border-accent dark:hover:border-accent h-6 w-6 rounded-full border-2 border-white"
													/> */}
                            </Tippy>
                          </a>
                        </Link>
                        <Link href={`/truckload/${slug || _id}`}>
                          <a>
                            <Tippy content={<span>creator: {name}</span>}>
                              {/* <img
														src={owner.image}
														alt="owner"
														layout="fill"
														className="dark:border-jacarta-600 hover:border-accent dark:hover:border-accent h-6 w-6 rounded-full border-2 border-white"
													/> */}
                            </Tippy>
                          </a>
                        </Link>
                      </div>
                    </div>
                  </figure>
                  <div className="mt-7 flex items-center justify-between">
                    <Link href={`/truckload/${slug || _id}`}>
                      <a>
                        <span className="font-display text-jacarta-700 hover:text-accent text-base dark:text-white">
                          {name}
                        </span>
                      </a>
                    </Link>

                    {/* auction dropdown  */}
                    <Auctions_dropdown classes="dark:hover:bg-jacarta-600 dropup hover:bg-jacarta-100 rounded-full" />
                  </div>
                  <div className="mt-2 text-sm">
                    <span className="dark:text-jacarta-200 text-jacarta-700 mr-1">
                      <span className="text-accent">Price:</span> ${price}
                    </span>
                  </div>

                  <div className="mt-2 text-sm">
                    <span className="dark:text-jacarta-300 text-jacarta-900">
                      <span className="text-accent ">Category:</span> {category}
                    </span>
                  </div>
                  <div className="mt-2 text-sm">
                    <span className="dark:text-jacarta-300 text-jacarta-500">
                      <span className="text-accent">Store:</span> {store}
                    </span>
                  </div>

                  <div className="mt-8 flex items-center justify-between">
                    {/* <Link href={`/item/${_id}`}> */}
                    <button
                      disabled={isSold}
                      className="text-accent font-display text-sm font-semibold"
                      onClick={() => dispatch(bidsModalShow(_id))}
                    >
                      Buy now
                    </button>
                    {/* </Link> */}
                  </div>
                </div>
              </article>
            </>
          );
        })}
    </div>
  );
};

export default CategoryItem;
